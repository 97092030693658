import React from 'react'
import '../css/style.css'
import Landing from './Landing';
import Section from './Section';


function Home() {
  return (
    <div>
      {/* LANDINGPAGE */}
  <Landing />
  {/* Section */}
  <Section />

    </div>
  );
}

export default Home